<template>
  <div class="page-content">
    <div class="container-fluid">
      <DataTable
        :refs="refs"
        :hasReportDropDown="false"
        :colspan="9"
        :hasPaidAmountStatistics="false"
        :hasCompanyFilter="isAdmin"
        :hasSearchFilter="true"
        :hasDateFromFilter="true"
        :hasDateToFilter="true"
        :hasTimeFromFilter="true"
        :hasTimeToFilter="true"
        :hasHashId="true"
        :hasMultiSelect="false"
        :hasPaymentMethod="true"
        :hasBulkAction="true"
        :hasExport="true"
        :hasShowEntries="true"
        :lblSearch="$t('app.search_by_name')"
        :fields="{
          id: 'id',
          title: 'title',
          price: 'price',
          time: 'time',
          date: 'date',
        }"
        :hasCompany="true"
        :company="$t('view.company')"
        :hasPaymentStatus="true"
        :payment_status="$t('view.payment_status')"
        :hasUser="true"
        :image="$t('view.user')"
        :image_style="'width: 40px;height: 40px;border-radius: 50%'"
        :hasDetail="true"
        :detail="$t('app.detail')"
        :hasPDF="true"
        :hasNoEdit="true"
        :hasPrice="true"
        :price="$t('app.price')"
        :hasDate="true"
        :hasActions="true"
        :actionable="true"
        :sorDataTable="true"
      >
      </DataTable>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DataTable from "@/components/DataTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "List",
  components: {
    DataTable,
  },
  data() {
    return {
      //
      addNew: false,
      refs: "reports",
      isAdmin: localStorage.getItem("role_key") == "root" ? true : false,
    };
  },
  watch: {
    //
  },
  mounted() {
    //
  },
  created() {
    setCurrentPageTitle(this.$t("nav.reports"), "bx bx-line-chart");
  },
  methods: {
    //
  },
});
</script>
